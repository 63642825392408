// @TODO split to multiple components
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Typography from '@material-ui/core/Typography/Typography';

import { ImageOverlayed } from '../components';
import {
  Footer,
  Layout,
} from '../containers';
import {
  Container,
  GradientBackground,
  SectionContainer,
  StyledButton,
} from '../styled';

class NeedMorePage extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const { data } = this.props;
    const { allMarkdownRemark } = data;
    const { edges } = allMarkdownRemark;
    const { contact } = edges.filter(el => el.node.frontmatter.contact)[0].node.frontmatter;
    const {
      needmore,
    } = edges.filter(el => el.node.frontmatter.needmore)[0].node.frontmatter;
    const {
      needmore_heading,
      needmore_button,
      needmore_image_text_1,
      needmore_image_text_2,
      needmore_image_text_3,
    } = needmore;


    return (
      <Layout>
        <GradientBackground style={{
          paddingBottom: '3.75rem', paddingTop: '8.125rem', display: 'flex', alignItems: 'center',
        }}
        >
          <Container>
            <div style={{ alignItems: 'flex-start' }}>
              <Typography variant="h3" gutterBottom>
                {needmore_heading}
              </Typography>
              <SectionContainer style={{ marginBottom: '2.5rem', marginTop: '1.5rem' }}>
                <ImageOverlayed data={data} href="http://www.linkvisuals.pl" imageName="image_adventureman169">
                  {needmore_image_text_1}
                </ImageOverlayed>
                <ImageOverlayed data={data} href="http://www.linkvisuals.pl" imageName="image_2_sm169">
                  {needmore_image_text_2}
                </ImageOverlayed>
                <ImageOverlayed data={data} href="http://www.linkvisuals.pl" imageName="image_3_sm169">
                  {needmore_image_text_3}
                </ImageOverlayed>
              </SectionContainer>
              <div style={{ textAlign: 'center' }}>
                <Link to="/form/">
                  <StyledButton variant="contained" spaceTop={1}>
                    {needmore_button}
                  </StyledButton>
                </Link>
              </div>
            </div>
          </Container>
        </GradientBackground>
        <Footer contactSectionContent={contact} />
      </Layout>
    );
  }
}

export default NeedMorePage;

export const query = graphql`
  query {
    image_adventureman169: file(relativePath: { eq: "adventureman-16-9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 460, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image_2_sm169: file(relativePath: { eq: "visual-effect-2-16-9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 460, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image_3_sm169: file(relativePath: { eq: "visual-effect-3-16-9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 460, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            contact {
              contact_address_heading
              contact_address_email
              contact_address_website
              contact_address_street
              contact_address_city
              contact_social_heading
              contact_copyright_informations
              contact_cookies_information
              contact_cookies_link
              contact_gdpr_informations
              contact_gdpr_link
            }
            needmore {
              needmore_heading
              needmore_button
              needmore_image_text_1
              needmore_image_text_2
              needmore_image_text_3
            }
          }
        }
      }
    }
  }
`;
